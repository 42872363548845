import React from 'react'
import { Helmet } from "react-helmet"
import Layout from '../components/Layout'
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebookSquare , faInstagram } from '@fortawesome/free-brands-svg-icons';
import '../styles/contact.css'

export default function contact() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Maker Recipe Sdn. Bhd. - Contact Us</title>
            </Helmet>
            
            <div className="ContactLocation">
                <Container>
                    <Row>
                        <Col xl="6" className="locationTitle">
                            <Row>
                                <Col md="12" className='locationSectionTitle'>
                                    <h1>Locations & Addresses</h1>
                                </Col>
                            </Row>
                        </Col>
                        <Col xl="6">
                            {/* <Row> */}
                                {/* <Col md="6"> */}
                                    <StaticImage 
                                        src="../images/contactUsImages/locations.JPG" 
                                        alt="Location Map"
                                        width={559}
                                        height={511} />
                                    
                                {/* </Col> */}
                            {/* </Row> */}
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="ContactInfo">
                <Container>
                    <Row>
                        <Col md="3" className="contactInfo-Title">
                            <h1>Contact Us</h1>
                        </Col>
                        <Col md="9">
                            <Row>
                                <Col md="12" className="emailAddress">
                                    <h2 >
                                        Email: <a  className= "emailLink" href="mailto:azamys@makerrecipe.com">azamys@makerrecipe.com</a>
                                    </h2>
                                </Col>
                                <Col md="12" className="phoneNumber">
                                    <h2>Phone Number: +60-4922 1770 </h2>
                                </Col>
                                <Col md="12" className="socialMedias">
                                    <div className="socialMedia-Title">
                                        <h2>Follow Us</h2>
                                    </div>
                                    <div className="socialMedia-Container">
                                        <div className="contact-Facebook">
                                            <a href="https://www.facebook.com/MakerRecipe">
                                                <FontAwesomeIcon icon={faFacebookSquare} className="footerIcon" />
                                            </a>
                                        </div>
                                        <div className="contact-Instagram">
                                            <a href=" https://www.instagram.com/karicap_o/">
                                                <FontAwesomeIcon icon={faInstagram} className="footerIcon" />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
